import React, { useState } from "react";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./App.scss";

import OptIn from "./components/OptIn/OptIn";

import LoaderAnimation from "./components/LoaderAnimation/LoaderAnimation";
import Header from "./components/Header/Header";
import Intro from "./components/Intro/Intro";
import SmallSearch from "./components/SmallSearch/SmallSearch";
import News from "./components/News/News";

import content from "./helpers/Content";

import CityParks from "./components/CityParks/CityParks";
import CityCount from "./components/CityCount/CityCount";
import Customers from "./components/Customers/Customers";
import Citydash from "./components/Citydash/Citydash";
import Cityflow from "./components/Cityflow/Cityflow";
import Citysim from "./components/Citysim/Citysim";
import Citychat from "./components/Citychat/Citychat";
import CityOps from "./components/CityOps/CityOps";
import CitySDK from "./components/CitySDK/CitySDK";
import CityAI from "./components/CityAI/CityAI";
import Privacy from "./components/Privacy/Privacy";
import Footer from "./components/Footer/Footer";

import TagManager from "react-gtm-module";
import getLanguage from "./helpers/getLanguage";
TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});

function App() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();
  
  content.init(setContentData);

  return (
      <div className='App'>
            <LoaderAnimation 
                content={contentData}
            />

          <OptIn
              content={contentData}
              language={language}
          />

          <Header
              content={contentData}
              language={language}
              setLanguage={setLanguage}
          />

          <Intro
              content={contentData}
              language={language}
          />

          <SmallSearch
              content={contentData}
              language={language}
          />

          <News
              content={contentData}
              language={language}
          />

          <Customers
              content={contentData}
              language={language}
          />

          <Citydash
              content={contentData}
              language={language}
          />
          
          <CityParks
              content={contentData}
              language={language}
          />

          <CityCount
              content={contentData}
              language={language}
          />

          <Cityflow
              content={contentData}
              language={language}
          />
          <Citysim
              content={contentData}
              language={language}
          />

          <Citychat
              content={contentData}
              language={language}
          />

          <CityOps
              content={contentData}
              language={language}
          />

          <CitySDK
              content={contentData}
              language={language}
          />

          <CityAI
              content={contentData}
              language={language}
          />

          <Privacy
              content={contentData}
              language={language}
          />

          <Footer
              content={contentData}
              language={language}
          />
      </div>
  );
}

export default App;
