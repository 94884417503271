import React from "react";

import "./IntroContent.scss";

function IntroContent(imagePath,introText,blobText,description_text,content,language) {
  return (
    <section id="Intro">
      <div className="container">
        <article className="half" id="hasDisclaimer">
          <div className="hasTitles">
            <h2 className="title">{introText}</h2>
          </div>

          <div className="hasDescription">
            <p className="description">{blobText}</p>
          </div>

          <div className="hasCTAs">
            <a href={content.Footer.Button["Relevent_Link"]}
               target="_blank"
               title={content.Introduction.Button[language]}
               className="cta"
               rel="noreferrer">
                {content.Introduction.Button[language]}
            </a>
          </div>
        </article>

        <article className="half" id="hasBlob">
          <svg id="blobSVGHolder" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path id="blob"
              // fill="#f76963" 
              fill="transparent" 
              d="M62.9,-35.1C74.2,-16.7,71.2,11.3,58.3,31.7C45.4,52.1,22.7,64.9,1,64.3C-20.7,63.7,-41.3,49.8,-55.1,28.9C-68.9,8,-75.8,-19.9,-65.4,-37.7C-55.1,-55.6,-27.5,-63.5,-0.9,-62.9C25.8,-62.4,51.5,-53.5,62.9,-35.1Z"
              transform="translate(100 100)"
            />
            <path id="blob_fake"
              fill="transparent" 
              d="M62.9,-35.1C74.2,-16.7,71.2,11.3,58.3,31.7C45.4,52.1,22.7,64.9,1,64.3C-20.7,63.7,-41.3,49.8,-55.1,28.9C-68.9,8,-75.8,-19.9,-65.4,-37.7C-55.1,-55.6,-27.5,-63.5,-0.9,-62.9C25.8,-62.4,51.5,-53.5,62.9,-35.1Z"
              transform="translate(100 100)"
            />
            <text>
            <textPath href="#blob_fake" startOffset={`${(100 - description_text.length) * 0.85}%`}>
              {description_text}
              <animate attributeName="startOffset" from="100%" to={`${(100 - description_text.length) * 0.85}%`} begin="10s" dur="0.5s" repeatCount="1" restart="whenNotActive"/>
            </textPath>
            </text>
            <defs>
              <clipPath id="shape">
                <path 
                  fill="transparent" 
                  d="M62.9,-35.1C74.2,-16.7,71.2,11.3,58.3,31.7C45.4,52.1,22.7,64.9,1,64.3C-20.7,63.7,-41.3,49.8,-55.1,28.9C-68.9,8,-75.8,-19.9,-65.4,-37.7C-55.1,-55.6,-27.5,-63.5,-0.9,-62.9C25.8,-62.4,51.5,-53.5,62.9,-35.1Z"
                  transform="translate(100 100) scale(0.96)"
                />
              </clipPath>
            </defs>
            <image width="251"
                   height="200"
                   clipPath="url(#shape)"
                   xlinkHref={imagePath} ></image>
          </svg>
        </article>
      </div>
    </section>
  );
}

export default IntroContent;